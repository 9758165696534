import { useIsomorphicViewportDevice } from '@zep/react';

import S from './LandingAgency.module.scss';

export const LandingAgency = (props: LandingAgencyProps) => {
  const {
    title,
    description,
    desktopImageSrc,
    tabletImageSrc,
    mobileImageSrc,
    imageAlt,
    isUseMotion = true,
  } = props;
  const { isTablet, isMobile } = useIsomorphicViewportDevice();

  return (
    <section className={S.partners}>
      <div data-motion={isUseMotion && 'scale-in'}>
        <p className={S.title}>{title}</p>
        <p className={S.sub_description}>{description}</p>
      </div>

      {((!isTablet && !isMobile) || tabletImageSrc || mobileImageSrc) && (
        <div className={S.partners_image}>
          {/* eslint-disable-next-line @next/next/no-img-element */}
          <img
            src={
              isMobile
                ? mobileImageSrc ?? ''
                : isTablet
                ? tabletImageSrc ?? ''
                : desktopImageSrc ?? ''
            }
            width={isMobile ? 560 : isTablet ? 1022 : 1623}
            height={isMobile ? 254 : isTablet ? 208 : 179}
            alt={imageAlt ?? ''}
            loading="lazy"
          />
        </div>
      )}
    </section>
  );
};

type LandingAgencyProps = {
  title: string;
  description: string;
  buttonText: string;
  buttonLink: string;
  desktopImageSrc: string;
  tabletImageSrc: string;
  mobileImageSrc: string;
  imageAlt: string;
  isUseMotion?: boolean;
};
