import * as React from 'react';
import { SVGProps } from 'react';
export const SendFillIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
    <path
      d="M17.867 2.009a.997.997 0 0 0-.422.159l-15 10a1 1 0 0 0 .326 1.805l7.398 1.741 7.698-13.705ZM11.805 16.529l5.59 5.223a1 1 0 0 0 1.682-.73l-.003-18.029a.999.999 0 0 0-.141-.512L11.805 16.53Z"
      fill={props.fill || `currentColor`}
    />
  </svg>
);
