import { CSSProperties } from 'react';
import cns from 'classnames';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';

import { ChevronRightIcon } from '@zep/icons';
import { useIsomorphicViewportDevice } from '@zep/react';
import { Button } from '@zep/ui';

import { joinUtmQueryParams } from '../../../utils';

import S from './LandingPremium.module.scss';

export const LandingPremium = (props: LandingPremiumProps) => {
  const {
    title,
    description,
    logo = 'landing',
    features,
    buttonText,
    buttonLink,
    backgroundColor = 'white',
    onButtonClick,
  } = props;
  const router = useRouter();
  const { isMobile } = useIsomorphicViewportDevice();
  const isInternal = buttonLink?.startsWith('/');

  return (
    <section
      className={cns(S.premium, {
        [S.gray]: backgroundColor === 'gray',
        [S.white]: backgroundColor === 'white',
      })}>
      <div className={S.premium_feature_box}>
        <div className={S.text_wrapper} data-motion="scale-in">
          {logo === 'landing' && (
            <div className={S.vip_bedge}>
              <Image
                src="/images/light/landing/vip-mint.svg"
                width="36"
                height="36"
                alt="vip"
              />
            </div>
          )}
          {logo === 'office' && (
            <Image
              src="/images/light/landing/zep_office.svg"
              width="164"
              height="32"
              alt="zep_office"
            />
          )}
          {title && (
            <h2
              className={S.title}
              dangerouslySetInnerHTML={{ __html: title }}
            />
          )}
          {description && <p className={S.sub_description}>{description}</p>}
        </div>

        {features && (
          <div className={S.premium_feature} data-motion="stagger-in">
            {features.map((item, index) => (
              <div
                key={item.name}
                className={S.feature_card}
                data-motion-item
                style={
                  { '--motion-delay': `${0.1 * index}s` } as CSSProperties
                }>
                <h3 dangerouslySetInnerHTML={{ __html: item.name }} />
                <ul>
                  {item.list?.map((item, index) => (
                    <li key={index}>
                      <Image
                        src="/images/light/landing/check_circle-gray.svg"
                        width="24"
                        height="24"
                        alt="cheeck circle"
                      />
                      <span>{item}</span>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        )}

        {buttonText && buttonLink && (
          <Link
            href={joinUtmQueryParams(
              buttonLink ?? 'https://premium-zep.super.site/eng',
              router,
            )}
            legacyBehavior
            passHref
            prefetch={false}>
            <Button
              as="a"
              size={isMobile ? 'md' : 'lg'}
              className={S.premium_button}
              target={isInternal ? '_self' : '_blank'}
              rightSlot={<ChevronRightIcon width={18} height={18} />}
              onClick={onButtonClick}>
              {buttonText}
            </Button>
          </Link>
        )}
      </div>
    </section>
  );
};

type LandingPremiumProps = {
  title: string;
  logo: 'landing' | 'office';
  description: string;
  features: {
    name: string;
    list: string[];
  }[];
  buttonText: string;
  buttonLink: string;
  backgroundColor: 'gray' | 'white';
  onButtonClick?: () => void;
};
